import React from "react";
import { NavLink} from "react-router-dom";

export function Navigation() {
    return <div className="navbar">
        <ul>
            <li className="nav nav-masthead justify-content-center">
                <NavLink className="nav-link" to="/" exact={true} activeClassName="active">Architecture</NavLink>
            </li>
            <li className="nav nav-masthead justify-content-center">
                <NavLink className="nav-link " to="/webapp" activeClassName="active">Web Application</NavLink>
            </li>
            <li className="nav nav-masthead justify-content-center">
                <NavLink className="nav-link " to="/agile" activeClassName="active">Agile</NavLink>
            </li>
        </ul>
    </div>;
}
