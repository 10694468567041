import {ITaskItem, ITaskTopic} from "../models/task-item";

export const dailyProjectChecklist: ITaskItem[] = [
    {
        id: 1,
        description: "Have you responded to all emails"
    }
];

export const agileChecklist: ITaskTopic[] = [

    {
        id: 100,
        description: "User Story",
        items: [
            {
                id: 1,
                description: "Can it be tested?"
            },
            {
                id: 1,
                description: "Input Validation"
            },
            {
                id: 1,
                description: "User Notifications"
            },
            {
                id: 1,
                description: "Data Requirements"
            },
            {
                id: 1,
                description: "UI/UX Requirements"
            }
        ]
    }

];
