import React from 'react';
import '../App.css';
import {ITaskTopic} from "../models/task-item";
import TaskTopic from "./task-topic";

export interface ICheckListViewProps {
    checklist: ITaskTopic[]
}

function TaskTopicList(props: ICheckListViewProps) {
    return (<>
        {
            props.checklist.map((topic, idx) =>
                    <TaskTopic topic={topic} index={idx}/>
            )
        }
    </>);
}

export default TaskTopicList;
