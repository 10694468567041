import React from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {Navigation} from "./navigation";
import TaskTopicList from "../components/task-topic-list";
import {architectureChecklist} from "../data/architecture";
import {webAppChecklist} from "../data/webapp";
import {agileChecklist} from "../data/project";

export function RoutedContent() {
    return (
        <BrowserRouter>
            <Navigation/>
            <Switch>
                <Route exact path="/">
                    <TaskTopicList checklist={architectureChecklist}/>
                </Route>
                <Route path="/webapp">
                    <TaskTopicList checklist={webAppChecklist}/>
                </Route>
                <Route path="/agile">
                    <TaskTopicList checklist={agileChecklist}/>
                </Route>
            </Switch>
        </BrowserRouter>
    );
}
