import {ITaskTopic} from "../models/task-item";

export const architectureChecklist: ITaskTopic[] = [
    {
        id: 100,
        description: "Logging",
        items: [
            {
                id: 1002,
                description: "Framework",
                resources: [
                    {
                        url: "https://datalust.co/seq",
                        description: "Seq Structured Logging"
                    },
                    {
                        url: "https://www.elastic.co/what-is/elk-stack",
                        description: "ELK Stack"
                    }
                ]
            },
            {
                id: 1003,
                description: "Storage"
            },
            {
                id: 1004,
                description: "Monitoring & Alerts",
                resources: [
                    {
                        url: "https://www.bugsnag.com/",
                        description: "Bugsnag"
                    }
                ]
            }
        ]
    },
    {
        id: 101,
        description: "Security",
        items: [
            {
                id: 1002,
                description: "Authorization"
            },
            {
                id: 1003,
                description: "Authentication"
            },
            {
                id: 1004,
                description: "Regional Availability"
            },
            {
                id: 1005,
                description: "Cert/Secret Management"
            }
        ]
    },
    {
        id: 102,
        description: "Deployment",
        items: [
            {
                id: 1002,
                description: "Runtime Environment"
            },
            {
                id: 1003,
                description: "Automation"
            },
            {
                id: 1004,
                description: "Change Control"
            },
            {
                id: 1005,
                description: "Monitors & Alerts"
            }
        ]
    },
    {
        id: 102,
        description: "Source Code - SCM",
        items: [
            {
                id: 1002,
                description: "Branch Strategy"
            },
            {
                id: 1003,
                description: "Merge Strategy"
            }
        ]
    },
    {
        id: 102,
        description: "Technical Stack",
        items: [
            {
                id: 1002,
                description: "Framework(s)"
            },
            {
                id: 1003,
                description: "Contribution guide"
            }
        ]
    },
    {
        id: 102,
        description: "Integrations",
        items: [
            {
                id: 1002,
                description: "Vendors"
            },
            {
                id: 1003,
                description: "Peer Software"
            }
        ]
    },
    {
        id: 102,
        description: "Performance",
        items: [
            {
                id: 1002,
                description: "Scaling"
            },
            {
                id: 1003,
                description: "Uptime / SLA"
            },
            {
                id: 1004,
                description: "Load Testing Plan"
            },
            {
                id: 1005,
                description: "Regional Availability"
            }

        ]
    }
];
