import {ITaskTopic} from "../models/task-item";

export const webAppChecklist: ITaskTopic[] = [
    {
        id: 100,
        description: "Quality",
        items: [
            {
                id: 1002,
                description: "Grammar, Spelling"
            },
            {
                id: 1003,
                description: "HTML Lint & Validate"
            },
            {
                id: 1004,
                description: "CSS Lint & Validate"
            },
            {
                id: 1004,
                description: "Link Lint & Validate"
            },
            {
                id: 1004,
                description: "I18N Lint & Validate"
            },
            {
                id: 1003,
                description: "Favicon"
            },
        ]
    },
    {
        id: 100,
        description: "Accessibility",
        items: [
            {
                id: 1003,
                description: "Color Contrast"
            },
            {
                id: 1004,
                description: "Navigation"
            },
            {
                id: 1004,
                description: "Forms"
            },
            {
                id: 1003,
                description: "Specify 'lang'"
            },
            {
                id: 1003,
                description: "WCAG 2.0 Review"
            }, {
                id: 1002,
                description: "Lint & Validate"
            }
        ]
    },
    {
        id: 102,
        description: "Security",
        items: [
            {
                id: 1002,
                description: "Cross-site concerns"
            },
            {
                id: 1003,
                description: "RoutedContent Security Policy"
            },
            {
                id: 1003,
                description: "Strict Transport"
            },
            {
                id: 1003,
                description: "SSL"
            },
            {
                id: 1003,
                description: "Security Scan"
            }
        ]
    },
    {
        id: 102,
        description: "Performance",
        items: [
            {
                id: 1002,
                description: "Scaling"
            },
            {
                id: 1003,
                description: "Page Speed"
            },
            {
                id: 1004,
                description: "Optimize Headers"
            },
            {
                id: 1005,
                description: "Optimize Images"
            }

        ]
    },
    {
        id: 101,
        description: "Logging",
        items: [
            {
                id: 1003,
                description: "Storage"
            },
            {
                id: 1004,
                description: "Monitoring & Alerts"
            },
        ]
    },
    {
        id: 102,
        description: "Mobile",
        items: [
            {
                id: 1002,
                description: "Mobile Support"
            },
            {
                id: 1003,
                description: "Device Tests"
            }
        ]
    },
    {
        id: 102,
        description: "Usability",
        items: [
            {
                id: 1002,
                description: "Custom Error Pages"
            },

            {
                id: 1003,
                description: "Printer Friendly"
            },
            {
                id: 1003,
                description: "User Friendly URLs"
            },
            {
                id: 1003,
                description: "Search"
            }
        ]
    },

    {
        id: 102,
        description: "SEO",
        items: [
            {
                id: 1002,
                description: "Sitemap"
            },
            {
                id: 1003,
                description: "robots.txt"
            },
            {
                id: 1003,
                description: "humans.txt"
            },
            {
                id: 1003,
                description: "SEO Lint & Scan"
            }
        ]
    }

];
