import React, {useState} from 'react';
import '../App.css';
import {ITaskItem} from "../models/task-item";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import TaskItemResources from "./task-item-resources";

export interface ITaskItemProps {
    item: ITaskItem;
    index: number;
}

function TaskItem(props: ITaskItemProps) {

    const [showResources, setShowResources] = useState<boolean>(false);
    const chevron = showResources ? faChevronDown : faChevronRight;

    function chevronClick() {
        setShowResources(!showResources);
    }

    return (
        <div className="card-item" key={"card_item_" + props.index}>
            <input type="checkbox" id={"item_" + props.item.id}/>
            <label className="label-inline"
                   htmlFor={"item_" + props.item.id}>{props.item.description} </label> <span className="icon-display"><FontAwesomeIcon icon={chevron} onClick={chevronClick}/></span>
            { showResources && props.item.resources ? <TaskItemResources resources={props.item.resources}/> : <></>}
        </div>
    );
}

export default TaskItem;
