import React from 'react';
import '../App.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {ITaskItem, ITaskTopic} from "../models/task-item";
import TaskItem from "./task-item";

export interface ITaskTopicProps {
    topic: ITaskTopic;
    index: number;
}

function TaskTopic(props: ITaskTopicProps) {
    return (
        <div className="card" key={"card_" + props.index}>
            <div className="card-content">
                <h4> {props.topic.description}</h4>
                {props.topic.items?.map((item: ITaskItem, idx) =>
                   <TaskItem item={item} index={idx}/>
                )}
            </div>
        </div>
    );
}

export default TaskTopic;
