import React from 'react';
import './App.css';
import {Header} from "./layout/header";
import {Footer} from "./layout/footer";
import {RoutedContent} from "./layout/routed-content";

function App() {
    return (
        <div className="container">
            <Header/>
            <div className="float-left">
               <RoutedContent/>
            </div>
            <Footer/>
        </div>
    );
}

export default App;
